@import "../../scss/theme.scss";

.signin-link {
  font-family: "ApexNew-Medium";
  font-size: 1em;
  display: block;
  margin-top: 20px;
  .signin-btn {
    all: unset;
    text-align: center;
    min-width: 240px;
    padding: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    &:focus {
      outline: none;
    }
    @include themify($themes) {
      color: themed("textColor");
      background-color: themed("buttonColor");
    }
    &:hover {
      @include themify($themes) {
        color: themed("buttonTextHoverColor");
      }
    }
    span {
      padding-left: 10px;
    }
    img {
      width: 24px;
    }
  }
  &:hover {
    text-decoration: none;
  }
}
