/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "ApexNew-Book";
  font-style: normal;
  font-weight: normal;
  src: url(ufonts.com_apexnew-book-opentype.woff);
}

@font-face {
  font-family: "ApexNew-Medium";
  font-style: normal;
  font-weight: normal;
  src: url(ufonts.com_apexnew-medium-opentype.woff);
}
