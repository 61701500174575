@import "./theme.scss";
@import "./responsive.scss";

// scrollbar styling for firefox
html {
  overflow-y: auto;
  scrollbar-color: #3c3939 #1b2024;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

// scroll bar styling for Chrome/Safari/Opera
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #1b2024;
}
::-webkit-scrollbar-thumb {
  background: #3c3939;
}

html,
body,
#root {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background-color: #1b2024;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.theme-dark,
.theme-light {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  font-family: "ApexNew-Book";
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
  .app-container {
    margin: 0 auto;
    width: 95vw;
  }
}

.Toastify__toast {
  @include break-point(mobile-view) {
    min-height: 50px;
    width: 75vw;
  }

  &-body {
    font-family: "ApexNew-Book";
    font-weight: bold;
  }

  &--error {
    background: #e23649;
  }

  &--success {
    background: #006fa4; 
  }
}

.required-field {
  color: #e23649;
}
