.searchinput {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .search-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  
  .searchdropdown {
    background-color: white;
    display: flex;
    width: 320px;
    border-radius: 5px;
    flex-direction: column;
    border: 1px solid black;
  }
  
  .dropdown:empty {
    border: none;
  }
  
  .dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
  }
  
  input {
    width: 220px;
    padding-left: 8px;
  }
  
  .search-inner {
    display: flex;
    width: 100vw;
  }

  .searchbox{
    border-radius: 5px;
    border-color: black;
    outline: none;
  }

  .search-btn{
    color: white;
    display: flex;
    font-weight: 600;
    padding: 5px 7px 5px 7px;
    background-color: #00a1ed;
    border-radius: 5px;
    text-transform: uppercase;
  }
  

  @media screen and (max-width: 700px) {
    .search-inner {
      display: flex;
      width: 100vw;
      flex-direction: row;
      justify-content: center;
  }
  
  .search-btn{
    color: white;
    display: flex;
    font-size: small;
    font-weight: 600;
    padding: 3px 5px 3px 5px;
    background-color: #00a1ed;
    border-radius: 5px;
    text-transform: uppercase;
  }
  }