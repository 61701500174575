@import "./theme.scss";
@import "./responsive.scss";

.form-wrapper {
  width: 100%;
  padding: 20px;
  @include themify($themes) {
    color: themed("textColor");
  }
  h4 {
    font-family: "ApexNew-Medium";
    @include themify($themes) {
      color: themed("textColor");
    }
  }
  .form-container {
    @include themify($themes) {
      background-color: themed("buttonColor");
    }
    padding: 30px;
    width: 100%;
    border-radius: 4px;
    margin-top: 20px;
    form {
      input,
      textarea,
      select {
        &:focus {
          @include themify($themes) {
            border-color: themed("fieldFocusBorder");
            color: themed("fieldFocusFontColor");
          }
        }
        @include themify($themes) {
          background-color: themed("inputFieldBgColor");
          color: themed("inputFieldColor");
          border-color: themed("inputFieldColor");
          box-shadow: unset;
        }
      }
      .fields-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-group {
          width: 45%;
          @include break-point(large-screen-view) {
            width: 30%;
          }
          @include break-point(mobile-view) {
            width: 100%;
          }
        }
      }
      @include break-point(mobile-view) {
        width: 100%;
      }
      .action-btn-wrapper {
        @include break-point(mobile-view) {
          text-align: center;
        }
      }
    }
    input[type="date"] {
      &::-webkit-calendar-picker-indicator {
        color: #fff;
        fill: currentColor;
      }
    }
    @include break-point(mobile-view) {
      padding: 20px;
    }
  }
}

input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    filter: invert(67%);
    cursor: pointer;
    &:hover {
      filter: invert(100%);
    }
  }
}
