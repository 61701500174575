@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 10px;
  a {
    text-decoration: none;
  }
  .reap-logo-heading-wrapper {
    display: flex;
    align-items: center;
    font-family: "ApexNew-Medium";
    .reap-live-logo {
      width: 150px;
      @include break-point(mobile-view) {
        width: 80px;
      }
    }
    .reap-live-heading {
      display: inline-block;
      padding: 5px 0 0 15px;
      font-size: 18px;
      @include themify($themes) {
        color: themed("mainHeadingColor");
      }
      @include break-point(extra-small) {
        max-width: 85px;
      }
      @include break-point(mobile-view) {
        line-height: 1.2;
        font-size: 12px;
        padding-left: 8px;
      }
    }
  }
  .login-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .profile-image {
      width: 36px;
      border-radius: 50%;
      @include break-point(mobile-view) {
        width: 24px;
      }
    }
    .user-name {
      font-family: "ApexNew-Medium";
      margin: 0 5px;
      font-size: 0.875em;
      font-weight: 600;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include break-point(mobile-view) {
        margin: 0 5px;
        font-size: 0.75em;
        max-width: 110px;
      }
    }
  }
  @include themify($themes) {
    color: themed("textColor");
  }
  @include break-point(mobile-view) {
    padding: 10px;
  }
}

.dropdown {
  font-family: "ApexNew-Medium";
  position: relative;
  display: inline-block;
  padding-right: 15px;
  .menu-hamburger {
    width: 36px;
    cursor: pointer;
    @include break-point(mobile-view) {
      width: 28px;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    min-width: 120px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    // scrollbar styling for firefox
    scrollbar-color: #3c3939 #262b2f;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    @include themify($themes) {
      background-color: themed("buttonColor");
    }
    a {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      border-radius: 5px;
      white-space: nowrap;
      @include themify($themes) {
        color: themed("textColor");
      }
      &:hover {
        font-weight: 600;
        @include themify($themes) {
          background-color: themed("tableHeaderBgColor");
          color: themed("buttonTextHoverColor");
        }
      }
      @include break-point(mobile-view) {
        padding: 10px;
      }
    }
    @include break-point(mobile-view) {
      font-size: 0.875em;
      min-width: 90px;
    }
    // scrollbar styling for Chrome/Safari/Opera
    &::-webkit-scrollbar-track {
      background: #262b2f;
    }
  }
  &:hover {
    .dropdown-content {
      display: block;
    }
  }
  @include break-point(mobile-view) {
    padding: 0;
  }
}

.pdficon {
  @include break-point(mobile-view) {
    width: 5px;
  }
}