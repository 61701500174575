@import './theme.scss';
@import './responsive.scss';

.heading-row {
  font-family: "ApexNew-Medium";
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin: 0;
    @include break-point(mobile-view) {
      font-size: 1.2em;
    }
  }
  @include themify($themes) {
    color: themed("headingColor");
  }
  @include break-point(mobile-view) {
    margin-top: 15px;
  }
}