@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

@mixin submit-cancel-btn() {
  all: unset;
  font-family: "ApexNew-Medium";
  padding: 8px 40px;
  margin: 16px 20px 0 0;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @include break-point(mobile-view) {
    padding: 6px 24px;
  }
}

.cancel-btn {
  @include submit-cancel-btn();
  @include themify($themes) {
    background-color: themed("cancelBtnColor");
    border-color: themed("cancelBtnColor");
    color: themed("buttonTxtColor");
  }
}

.submit-update-btn {
  @include submit-cancel-btn();
  margin-right: 0;
  @include themify($themes) {
    background-color: themed("successSubmitColor");
    border-color: themed("successSubmitColor");
    color: themed("buttonTxtColor");
  }
}

.add-btn {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 0.875em;
  width: 127px;
  height: 42px;
  &:focus {
    outline: none;
  }
  .txt-sign-wrapper {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    .plus-sign {
      font-size: 1.5em;
      padding-right: 4px;
    }
  }
  @include themify($themes) {
    background-color: themed("successSubmitColor");
    color: themed("buttonTxtColor");
  }
  @include break-point(mobile-view) {
    width: 80px;
    height: 28px;
    font-size: 0.625em;
  }
}

.upload-download-btn {
  @include submit-cancel-btn();
  margin: 0 auto;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 10px 4px 0;
  }
  @include break-point(mobile-view) {
    font-size: 0.875em;
    padding: 6px 10 px;
  }
  @include themify($themes) {
    background-color: themed("successSubmitColor");
    border-color: themed("successSubmitColor");
    color: themed("buttonTxtColor");
  }
}
