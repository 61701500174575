/*
 * Theme definitions
 */

$themes: (
  light: (
    backgroundColor: #EEE8E7,
    textColor: #222,
    headingColor: #222,
    buttonTextHoverColor: #222,
    buttonColor: #ccc,
    buttonBorder: 1px solid #ccc,
    tableRowColor: #ccc,
    inputFieldBgColor: #fff,
    inputFieldColor: #222,
    tableHeaderBgColor: #ccc,
    tableBodyBgColor: #ccc,
    tableTxtColor: #222,
    menuBgColor: #f1f1f1,
    menuBgHover: #ddd,
    listMenuBgColor: #ccc,
    listMenuFocusColor: #bcbdbf,
    inputBorderActive: 0 0 0 0.2rem #1f2821,
    dropDownEntriesColor: #1B2024,
    cancelBtnColor: #727577,
    buttonTxtColor: #fff,
    errorDeleteColor: #e23649,
    successSubmitColor: #00a1ed,
    fieldFocusBorder: #d2d3d4,
    fieldFocusFontColor: #fff,
    mainHeadingColor: #00AEEF,
  ),
  dark: (
    backgroundColor: #1B2024,
    textColor: #D2D3D4,
    headingColor: #fff,
    buttonTextHoverColor: #fff,
    buttonColor: #262B2F,
    buttonBorder: 1px solid #333,
    tableRowColor: #222,
    inputFieldBgColor: #262B2F,
    inputFieldColor: #A6A7A9,
    tableHeaderBgColor: #1F2326,
    tableBodyBgColor: #262b2f,
    tableTxtColor: #D2D3D4,
    menuBgColor: #f1f1f1,
    menuBgHover: #ddd,
    listMenuBgColor: #ccc,
    listMenuFocusColor: #bcbdbf,
    inputBorderActive: rgba(130,138,145,.5),
    inputBoxShadowActive: 0 0 0 0.2rem rgba(130,138,145,.5),
    dropDownEntriesColor: #1B2024,
    cancelBtnColor: #727577,
    buttonTxtColor: #fff,
    errorDeleteColor: #e23649,
    successSubmitColor: #00a1ed,
    fieldFocusBorder: #d2d3d4,
    fieldFocusFontColor: #fff,
    mainHeadingColor: #00AEEF,
  ),
);

/*
 * Implementation of themes
 */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
