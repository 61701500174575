@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

.upload-download-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  h3 {
    margin: 15px 0;
    @include break-point(mobile-view) {
      margin: 5px 0;
      font-size: 1.5em;
    }
  }
  .action-btn-container {
    display: flex;
    min-height: 155px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .select-upload-wrapper {
      width: 230px;
      @include break-point(mobile-view) {
        width: 200px;
      }
    }
    .text-action-container {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .selected-file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        all: unset;
        cursor: pointer;
        padding: 2px;
        &:focus {
          outline: none;
        }
      }
      .remove-file {
        margin: 0 12px;
        &:hover {
          svg {
            @include themify($themes) {
              color: themed("errorDeleteColor");
            }
          }
        }
      }
      .upload-file {
        &:hover {
          svg {
            @include themify($themes) {
              color: themed("successSubmitColor");
            }
          }
        }
      }
      @include break-point(mobile-view) {
      margin-bottom: 5px;
    }
    }
    @include break-point(mobile-view) {
      min-height: 115px;
    }
  }
  @include themify($themes) {
    color: themed("textColor");
    background-color: themed("buttonColor");
  }
  @include break-point(mobile-view) {
    max-width: 300px;
  }
  @include break-point(landscape) {
    max-width: 360px;
    transform: translate(-50%, -35%);
  }
}
