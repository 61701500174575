@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

.table-container {
  padding: 20px 0;
  .table {
    border: none;
    word-wrap: break-word;
    thead {
      font-family: "ApexNew-Medium";
      th {
        font-size: 1em;
        border: none;
        outline: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
          margin-left: 10px;
          @include break-point(mobile-view) {
            position: absolute;
            left: -10px;
            top: 11px;
            font-size: 8px;
          }
        }
        &:last-child {
          text-align: center;
        }
        @include themify($themes) {
          background-color: themed("tableHeaderBgColor");
        }
        @include break-point(mobile-view) {
          font-size: 0.750em;
          padding: 8px;
          position: relative;
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid #2f3438;
        td {
          font-size: 0.875em;
          border: none;
          padding-top: 18px;
          padding-bottom: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include break-point(mobile-view) {
            padding: 10px 4px 10px 8px;
            font-size: 0.75em;
          }
        }
        @include themify($themes) {
          background-color: themed("tableBodyBgColor");
        }
      }
    }
    @include themify($themes) {
      color: themed("tableTxtColor");
    }
  }
  .no-record-text {
    @include themify($themes) {
      color: themed("textColor");
    }
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .no-record-text {
    margin-top: 100px;
  }
}

.react-bootstrap-table-pagination {
  font-family: "ApexNew-Medium";
  &.row {
    @include break-point(mobile-view) {
      margin: 0;
    }
  }
  #pageDropDown {
    outline: none;
    border: none;
    @include themify($themes) {
      background-color: themed("tableHeaderBgColor");
      color: themed("tableTxtColor");
    }
    @include break-point(mobile-view) {
      font-size: 0.75em;
      padding: 6px;
    }
  }
  .dropdown-menu {
    min-width: 80px;
    border-radius: 5px;
    @include themify($themes) {
      background-color: themed("tableBodyBgColor");
    }
    .dropdown-item {
      a {
        @include themify($themes) {
          color: themed("tableTxtColor");
        }
      }
      &:hover {
        @include themify($themes) {
          background-color: themed("tableBodyBgColor");
        }
      }
      @include break-point(mobile-view) {
        padding: 4px 8px;
      }
    }
    @include break-point(mobile-view) {
      min-width: 50px;
      text-align: center;
      font-size: 0.75em;
    }
  }
  .pagination {
    justify-content: flex-end;
    .page-item {
      .page-link {
        &:focus {
          box-shadow: none;
        }
        @include themify($themes) {
          background-color: themed("tableBodyBgColor");
          border-color: themed("tableBodyBgColor");
          color: themed("textColor");
        }
        @include break-point(mobile-view) {
          font-size: 0.625em;
          padding: 2px 4px;
        }
      }
      &.active {
        .page-link {
          @include themify($themes) {
            color: themed("successSubmitColor");
          }
        }
      }
    }
    @include break-point(mobile-view) {
      justify-content: flex-start;
      margin: 5px 0;
    }
  }
  .react-bootstrap-table-pagination-total {
    @include break-point(mobile-view) {
      font-size: 0.75em;
    }
  }
  @include themify($themes) {
    color: themed("tableTxtColor");
  }
}
