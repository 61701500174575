// conditional scss for media query
@mixin break-point($point) {
  @if $point == large-screen-view {
    @media screen and (min-width: 1400px) {
      @content;
    }
  } @else if $point == tablet-view {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == mobile-view {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $point == landscape {
    @media screen and (max-height: 420px) and (max-width: 825px) {
      @content;
    }
  } @else if $point == large-screen-height {
    @media screen and (min-height: 1000px) {
      @content;
    }
  } @else if $point == extra-small {
    @media screen and (max-width: 320px) {
      @content;
    }
  } @else if $point == ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
}
