@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

.action-cell {
  display: flex;
  justify-content: center;
  align-items: baseline;
  button {
    all: unset;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        svg {
          @include themify($themes) {
            color: inherit;
          }
        }
      }
    }
  }
  .edit-btn {
    margin-right: 10px;
    &:hover {
      svg {
        @include themify($themes) {
          color: themed("successSubmitColor");
        }
      }
    }
    svg {
      @include break-point(mobile-view) {
        zoom: 0.75;
      }
    }
    @include break-point(mobile-view) {
      margin-right: 15px;
    }
  }
  .delete-btn {
    &:hover {
      svg {
        @include themify($themes) {
          color: themed("errorDeleteColor");
        }
      }
    }
    svg {
      @include break-point(mobile-view) {
        zoom: 0.75;
      }
    }
  }
}
