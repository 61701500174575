@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

.missing-container {
  max-width: 450px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .missing-wrapper {
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    .live-logo {
      max-width: 192px;
    }
    .missing-text-wrapper {
      display: inline-block;
      margin-top: 30px;
      @include themify($themes) {
        color: themed("textColor");
      }
      h5 {
        margin-top: 20px;
      }
      a {
        text-decoration: underline;
        @include themify($themes) {
          color: themed("successSubmitColor");
        }
      }
    }
    @include themify($themes) {
      background-color: themed("buttonColor");
      color: themed("textColor");
    }
  }
  @include break-point(mobile-view) {
    max-width: 300px;
  }
}
