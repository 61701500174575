@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

.auth-info-container {
  max-width: 450px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .auth-info-wrapper {
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    .live-logo {
      max-width: 192px;
      @include break-point(mobile-view) {
        max-width: 100px;
      }
    }
    @include themify($themes) {
      background-color: themed("buttonColor");
      color: themed("textColor");
    }
  }
  @include break-point(mobile-view) {
    max-width: 300px;
  }
}
