@import "../../scss/responsive.scss";

.logout-btn {
  all: unset;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  img {
    width: 24px;
    @include break-point(mobile-view) {
      width: 18px;
    }
  }
}
