@import "./theme.scss";

.react-select-container {
  cursor: not-allowed !important;
  .react-select__control {
    font-family: "ApexNew-Book";
    @include themify($themes) {
      box-shadow: unset;
      background-color: themed("inputFieldBgColor");
      border-color: themed("inputFieldColor");
    }
    &:hover {
      @include themify($themes) {
        box-shadow: unset;
        border-color: themed("inputFieldColor");
      }
    }
    .react-select__placeholder {
      @include themify($themes) {
        color: themed("inputFieldColor");
      }
    }
    .react-select__single-value {
      @include themify($themes) {
        color: themed("inputFieldColor");
      }
    }
    .react-select__indicator.react-select__dropdown-indicator {
      cursor: pointer;
      fieldFocusBorder &:focus {
        @include themify($themes) {
          color: themed("inputFieldColor");
        }
      }
      &:hover {
        @include themify($themes) {
          color: themed("inputFieldColor");
        }
      }
      @include themify($themes) {
        color: themed("inputFieldColor");
      }
    }
    .react-select__indicator.react-select__clear-indicator {
      cursor: pointer;
      &:hover {
        @include themify($themes) {
          color: themed("buttonTextHoverColor");
        }
      }
      @include themify($themes) {
        color: themed("inputFieldColor");
      }
    }
    .react-select__indicator-separator {
      @include themify($themes) {
        background-color: themed("inputFieldColor");
      }
    }
    .react-select__multi-value {
      font-family: "ApexNew-Book";
      border: 1px solid;
      @include themify($themes) {
        background-color: themed("inputFieldBgColor");
        border-color: themed("inputFieldColor");
        color: themed("inputFieldColor");
      }
      .react-select__multi-value__label {
        @include themify($themes) {
          background-color: themed("inputFieldBgColor");
          border-color: themed("inputFieldColor");
          color: themed("inputFieldColor");
        }
      }
      .react-select__multi-value__remove {
        cursor: pointer;
        svg {
          @include themify($themes) {
            color: themed("inputFieldColor");
          }
        }
        &:hover {
          @include themify($themes) {
            background-color: themed("inputFieldBgColor");
            svg {
              color: themed("buttonTextHoverColor");
            }
          }
        }
      }
    }
    .react-select__input {
      @include themify($themes) {
        color: themed("inputFieldColor");
      }
    }
  }
  &.react-select--is-disabled {
    opacity: 0.5;
  }
}

.react-select__menu-list {
  font-family: "ApexNew-Book";
  @include themify($themes) {
    box-shadow: unset;
    border: 1px solid;
    border-color: themed("fieldFocusBorder");
  }
  .react-select__option {
    @include themify($themes) {
      background-color: themed("inputFieldBgColor");
      color: themed("inputFieldColor");
    }
  }
  .react-select__option--is-focused {
    @include themify($themes) {
      background-color: themed("inputFieldBgColor");
      color: themed("buttonTextHoverColor");
    }
  }
  .react-select__option--is-selected {
    @include themify($themes) {
      background-color: themed("successSubmitColor") !important;
      color: themed("buttonTextHoverColor");
    }
  }
}

.react-select__menu {
  font-family: "ApexNew-Book";
  .react-select__menu-list {
    @include themify($themes) {
      box-shadow: unset;
      border: 1px solid;
      border-color: themed("fieldFocusBorder");
      background-color: themed("inputFieldBgColor");
      color: themed("inputFieldColor");
    }
    .react-select__option {
      cursor: pointer;
    }
  }
}
